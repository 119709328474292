'use client';

import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useAccount, useSignMessage } from 'wagmi';
import authService from '../services/auth';
import toast from 'react-hot-toast';
import { useLocalStorage } from 'usehooks-ts';

type Authentication = {
  authenticated: boolean;
  address: string;
  token: string | null;
};

type AuthContextType = {
  authenticated: Authentication;
  signOut: () => void;
  signIn: (address: string) => Promise<void>;
};

const defaultAuthentication: Authentication = {
  authenticated: false,
  address: '',
  token: null,
};

const AuthContext = createContext<AuthContextType>({
  authenticated: defaultAuthentication,
  signOut: () => {
    throw new Error('signOut function not implemented');
  },
  signIn: async () => {
    throw new Error('signIn function not implemented');
  },
});

function AuthContextProvider({ children }: PropsWithChildren) {
  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [authentication, setAuthentication] = useLocalStorage<Authentication>(
    'authentication',
    defaultAuthentication
  );

  useEffect(() => {
    if (address === undefined) {
      return;
    }

    if (address === null) {
      setAuthentication(defaultAuthentication);
      return;
    }

    if (
      authentication.authenticated &&
      address.toLowerCase() !== authentication.address.toLowerCase()
    ) {
      setAuthentication(defaultAuthentication);
    }
  }, [address, authentication.address, authentication.authenticated, setAuthentication]);

  async function signIn(address: string) {
    try {
      // Fetch SIWE message
      const [siweMessage, error] = await authService.getSIWE(address);
      if (error || !siweMessage) {
        toast.error(error || 'Error fetching SIWE message');
        return;
      }

      // Sign the SIWE message
      const signature = await signMessageAsync({ message: siweMessage });
      if (!signature) {
        toast.error('Failed to obtain signature');
        return;
      }

      // Verify the signature and obtain JWT
      const response = await authService.verifySIWE(siweMessage, signature);
      if (response.success && response.token) {
        setAuthentication({ authenticated: true, address, token: response.token });
        toast.success('Signed in successfully');
      } else {
        console.error('Sign-in failed:', response.error);
        toast.error('Sign in Failed: ' + (response.error || 'Unknown error'));
      }
    } catch (err) {
      console.error('Error during sign-in:', err);
      toast.error('Sign in failed');
    }
  }

  function signOut() {
    // Clear authentication state
    setAuthentication(defaultAuthentication);
    toast.success('Signed out successfully');
    // Optionally, notify the backend about sign-out
  }

  const contextValue: AuthContextType = useMemo(
    () => ({
      authenticated: authentication,
      signOut,
      signIn,
    }),
    [authentication, signOut, signIn]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthContextProvider');
  }
  return context;
}

export { AuthContextProvider, useAuthContext };


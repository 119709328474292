'use client';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useAccount } from 'wagmi';
import toast from 'react-hot-toast';
import { Identity, useIdentity, useUpdateIdentity } from 'services/identity';
import { zeroAddress } from 'viem';
import { useAuthContext } from './auth';

export const sadPepe = 'https://immutable-cdn.s3.us-east-2.amazonaws.com/identity-icons/sad-pepe.png';
export const shockPepe = 'https://immutable-cdn.s3.us-east-2.amazonaws.com/identity-icons/shock-pepe.png';
export const yepPepe = 'https://immutable-cdn.s3.us-east-2.amazonaws.com/identity-icons/yep-pepe.png';

export const defaultIdentity: Identity = {
  id: 0,
  address: zeroAddress,
  username: 'Anonymous',
  avatar: sadPepe,
  dateCreated: new Date(),
  dateUpdated: new Date(),
};

type IdentityContextType = {
  identity: {
    identity: Identity;
    isFetching: boolean;
    isError: boolean;
  };
  updateIdentity: (params: Partial<Identity>) => void;
};

const IdentityContext = createContext<IdentityContextType>({
  identity: {
    identity: defaultIdentity,
    isFetching: false,
    isError: false,
  },
  updateIdentity: () => {
    throw new Error('updateIdentity not implemented');
  },
});

function IdentityContextProvider({ children }: PropsWithChildren) {
  const { address } = useAccount();
  const { data: identity, isFetching, isError } = useIdentity(address);

  const { mutateAsync } = useUpdateIdentity();

  const { authenticated } = useAuthContext();

  async function updateIdentity({ username, avatar }: { username?: string; avatar?: string }) {
    if (!address) {
      console.error('No address found');
      return;
    }

    console.log('Updating identity', { address, username, avatar });
    try {
      if (!authenticated.token) {
        toast.error('Not authenticated');
        return;
      }
      const res = await mutateAsync({ authToken: authenticated.token, address, username, avatar });
      console.log(res);

    } catch (e) {
      console.error(e);
      toast.error('Error updating identity');
    }
  }

  const contextValue: IdentityContextType = useMemo(
    () => ({
      identity: {
        identity: identity || defaultIdentity,
        isFetching,
        isError,
      },
      updateIdentity,
    }),
    [identity],
  );

  const Provider = IdentityContext.Provider;

  return <Provider value={contextValue}>{children}</Provider>;
}

function useIdentityContext() {
  const context = useContext(IdentityContext);
  if (!context) {
    throw new Error('uuseIdentityContext must be used within an IdentityContextProvider');
  }
  return context;
}

export { IdentityContextProvider, useIdentityContext };

'use client';

import { PropsWithChildren, useState, useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { bottomBarPortalID } from 'utils/portals';
import ConditionalBackground from '~/ui/conditional-bg';
import NavBarContent from '~/nav-bar/content';
import TopBar from '~/nav-bar/nav-bar';

function DesktopLayoutContent({ children }: PropsWithChildren) {
  return (
    <div className='flex h-full overflow-hidden overscroll-none'>
      <div className='flex flex-col w-full h-full overflow-y-scroll overscroll-none '>
        {children}
        <div className='block ' id={bottomBarPortalID}></div>
      </div>
      <div className='px-4 border-l border-purple-700 bg-primary'>
        <NavBarContent />
      </div>
    </div>
  );
}

function MobileLayoutContent({ children }: PropsWithChildren) {
  return (
    <>
      <div className='relative flex flex-col h-full overflow-y-scroll border-t border-gray-700 overscroll-none '>
        {children}
      </div>
      <div className='block' id={bottomBarPortalID}></div>
    </>
  );
}

function LayoutContent({ children }: PropsWithChildren) {
  const { width } = useWindowSize();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  const mobile = width < 1024;

  return (
    <div className='flex flex-col w-screen h-screen overscroll-none'>
      <TopBar />
      {!mobile && <DesktopLayoutContent>{children}</DesktopLayoutContent>}
      {mobile && <MobileLayoutContent>{children}</MobileLayoutContent>}
      <ConditionalBackground />
    </div>
  );
}

export default LayoutContent;

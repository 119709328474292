import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { apiURL } from './api';
import toast from 'react-hot-toast';
import { defaultIdentity } from 'providers/identity';

export enum IdentityRoutes {
  GET_IDENTITY = '/api/v1/identity',
  UPDATE_IDENTITY = '/api/v1/identity',
}

export type Identity = {
  id: number;
  address: string;
  dateCreated: Date;
  dateUpdated: Date;
  username: string;
  avatar: string;
  postCount: number;
  likeCount: number;
};

const fetchIdentity = async ({ queryKey }: { queryKey: any }): Promise<Identity | null> => {
  try {
    const [_, { address }] = queryKey;
    const response = await fetch(`${apiURL}${IdentityRoutes.GET_IDENTITY}/${address}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const identityRes = await response.json();
    const identity: Identity = {
      id: identityRes.id,
      address: identityRes.address,
      username: identityRes.username || defaultIdentity.username,
      avatar: identityRes.avatar || defaultIdentity.avatar,
      dateCreated: identityRes.date_created,
      dateUpdated: identityRes.date_updated,
      postCount: identityRes.post_count,
      likeCount: identityRes.like_count,
    };

    return identity;
  } catch (e) {
    console.error(e);
    return null;
  }
};

type UpdateIdentityParams = {
  authToken: string;
  address: string;
  username?: string;
  avatar?: string;
};

const updateIdentity = async ({ authToken, address, username, avatar }: UpdateIdentityParams): Promise<{ id: number } | null> => {
  try {
    const response = await fetch(`${apiURL}${IdentityRoutes.UPDATE_IDENTITY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ address, username, avatar }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (e) {
    console.error(e);
    throw new Error('Error updating identity');
  }
};

export const useIdentity = (address: string | undefined | null) =>
  useQuery({
    queryKey: ['identity', { address }],
    queryFn: fetchIdentity,
    enabled: !!address,
  });

export const useUpdateIdentity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateIdentity,
    onSuccess: () => {
      toast.success('Identity updated');
      queryClient.invalidateQueries({ queryKey: ['identity'] });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

function ConditionalBackground() {
  const pathname = usePathname();

  const bgRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (bgRef.current) {
      if (pathname === '/posts' || pathname === '/') {
        bgRef.current.style.opacity = '0';
        bgRef.current.style.visibility = 'hidden';
      } else {
        bgRef.current.style.opacity = '1';
        bgRef.current.style.visibility = 'visible';
      }
    }
  }, [pathname]);

  return (
    <iframe
      ref={bgRef}
      style={{ opacity: 0, visibility: 'hidden', background: 'transparent' }}
      onLoad={(e) => {
        // @ts-ignore
        e.target.style.opacity = 1;
        // @ts-ignore
        e.target.style.visibility = 'visible';
      }}
      src='/bg/index.html'
      className='fixed w-screen -z-10 h-screen2'
      title='bg'
    />
  );
}

export default ConditionalBackground;

import { useSyncWagmiConfig } from '@lifi/wallet-management';
import { useRef, type FC, type PropsWithChildren } from 'react';
import type { Config } from 'wagmi';
import { WagmiProvider } from 'wagmi';
import config, { wagmiChains, wagmiConnectors } from 'services/wagmi/config';

export const WalletProvider: FC<PropsWithChildren> = ({ children }) => {
  // @ts-ignore
  const wagmi = useRef<Config>();

  if (!wagmi.current) {
    wagmi.current = config;
  }

  // @ts-ignore
  useSyncWagmiConfig(wagmi.current, wagmiConnectors, wagmiChains);

  return (
    <WagmiProvider config={wagmi.current as any} reconnectOnMount={false}>
      {children}
    </WagmiProvider>
  );
};

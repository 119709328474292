import { http, createConfig } from 'wagmi';
import { sepolia, arbitrum } from 'wagmi/chains';
import { metaMask, walletConnect, injected, safe } from 'wagmi/connectors';

export const walletConnectProjectID = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;
if (!walletConnectProjectID) {
  throw new Error('NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID is not set');
}

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}

export const wagmiConnectors = [
  walletConnect({ projectId: walletConnectProjectID }),
  metaMask({
    dappMetadata: {
      name: 'Immutable Organization',
      url: 'https://immutable.com',
    },
  }),
  injected(),
  safe(),
];
export const wagmiChains = [sepolia, arbitrum];

const config = createConfig({
  // @ts-ignore
  chains: wagmiChains,
  transports: {
    [sepolia.id]: http(),
    [arbitrum.id]: http(),
  },
  connectors: wagmiConnectors,
  autoConnect: true,
});

export default config;
